import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();



AOS.init();

// You can also pass an optional settings object
// below listed default settings
AOS.init({
    // Global settings:
    disable: 'mobile', // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 1000, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});

// Schaltet mir die 100vh fullheight Navbar aus sobald ich geklickt habe


$(document).ready(function($) {
    $('.nav__list li a').click(function() {
        if(Foundation.MediaQuery.current == 'small'){
            $('.nav__list').css('display', 'none');
            $('.nav__list').removeClass('visible');
        }
    });
});

$(document).ready(function($) {
    $('.nav__list li a').click(function() {
        if(Foundation.MediaQuery.current == 'medium'){
            $('.nav__list').css('display', 'none');
            $('.nav__list').removeClass('visible');
        }
    });
});




// fixed Header



$(document).on("scroll", function() {

    if($(document).scrollTop()>100) {
        $("header").removeClass("large").addClass("small");
    } else {
        $("header").removeClass("small").addClass("large");
    }

});


// aktiviert mir die Klasse «clicked» beim geklickten Punkt und geht wieder weg wenn ich einen zweiten Punkt anklicke


$('.nav__list li a').on('click', function(){
    $('.nav__list li a.clicked').removeClass('clicked');
    $(this).addClass('clicked');
});



$('#menu-button').click(function () {
    $(this).toggleClass('open');
    $('.nav__list').toggleClass('visible');
    $('body').css('overflow', 'hidden');
});

